// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);

@import "~vue-snotify/styles/material";
@import "customMultiselect";
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
@import '~vue-datetime/dist/vue-datetime.css';
@import "custom";
@import 'primevue/resources/themes/saga-blue/theme.css';
@import 'primevue/resources/primevue.min.css';
@import 'primeicons/primeicons.css';
